import { useState, useEffect } from "react";
import Destination from "./interfaces";

const useLocal = (initialState: Array<Destination>) => {
    const [listDestination, updateList] = useState(initialState);

    useEffect(() => {
        localStorage.setItem('listDest', JSON.stringify(listDestination))
    }, [listDestination])

  return [listDestination, updateList];
};

export default useLocal;