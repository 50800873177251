import Destination from '../../lib/interfaces';
import DestinationForm from '../DestinationForm/DestinationForm';
import DestinationItem from "../DestinationItem/DestinationItem";
import './App.scss';
import {useState} from 'react';
import useLocal from '../../lib/localHook'
import DestinationContext from '../../lib/DestinationContext';

function App() {

  const [modalOpen, setModalOpen] = useState(false)

  const [listDestinations, updateList] = useLocal([{
      name: 'New York',
      adress: 'New York, NY 10004, United States',
      imgLink: 'https://www.mesquer-quimiac.fr/wp-content/uploads/2022/04/07-19_conf-UPM.jpg',
      nbResident: 8.4,
      nbHotel: 12000,
      avgIncome: 100000, 
      area: 783.8,
      isActivate: false
  },
  {
      name: 'Tokyo',
      adress: '4 Chome-2-8 Shibakoen, Minato City, Tokyo 105-0011, Japan',
      imgLink: 'https://wallpapers.com/images/high/kiyomizu-dera-temple-kyoto-japan-llk3nbdkm05wqn0c.jpg',
      nbResident: 13.9,
      nbHotel: 7000,
      avgIncome: 70000, 
      area: 627.6,
      isActivate: true
  },
  {
      name: 'London',
      adress: 'London SW1A 0AA, United Kingdom',
      imgLink: 'https://cdn.getyourguide.com/img/location/5b8e2640b4d58.jpeg/99.jpg',
      nbResident: 8.98,
      nbHotel: 10000,
      avgIncome: 90000, 
      area: 1572,
      isActivate: true
  },
  {
      name: 'Paris',
      adress: "Pl. de l'Hôtel de Ville, 75004 Paris",
      imgLink: 'https://assets.website-files.com/6045dd0ed9226efaab53a0a0/605499f560b0547168fd20a6_europe%20(1).jpg',
      nbResident: 2.1,
      nbHotel: 7500,
      avgIncome: 50000, 
      area: 105.4,
      isActivate: true
  },
  {
      name: 'Copenhagen',
      adress: 'Rådhuspladsen 1, 1550 København, Denmark',
      imgLink: 'https://www.trafalgar.com/real-word/wp-content/uploads/sites/3/2020/08/Untitled-design-2020-08-07T192629.397.jpg',
      nbResident: 3.4,
      nbHotel: 5000,
      avgIncome: 70000, 
      area: 88.25,
      isActivate: false
  },
  {
    name: 'Tehran',
    adress: 'Tehran, Azadi Square, Iran',
    imgLink: 'https://i0.wp.com/www.mypersiancorner.com/wp-content/uploads/2019/10/mohammad-amirahmadi-ShZzflXymPI-unsplash-copy.jpg?resize=1080%2C720&ssl=1',
    nbResident: 8.6,
    nbHotel: 400,
    avgIncome: 30000, 
    area: 730,
    isActivate: false
  }] as Array<Destination>) as [Array<Destination>, (value: Array<Destination>) => void];

  return (
    <DestinationContext.Provider value={{listDestinations,  updateList}}>
      <header className='header'>
        <h1 className='header__title'>Destinations</h1>
        <button onClick={() => setModalOpen(!modalOpen)} className='header__button'>+ Ajouter</button>
      </header>  
      <main>
        <div className="destinationContainer">
          {listDestinations.map((destination: Destination) => <DestinationItem content={destination} />)}
        </div>
        <DestinationForm isShow={modalOpen} clickIsOutside={() => setModalOpen(!modalOpen)}/>
      </main>
    </DestinationContext.Provider>
  );
}

export default App;
