import './DestinationItem.scss';
import {useContext, useState} from 'react';
import Destination from "../../lib/interfaces";
import Switch from '../Switch/Switch';
import DestinationContext from '../../lib/DestinationContext';

function DestinationItem(props: {content: Destination}) {

    const {
        content
    } = props

    const { listDestinations, updateList } = useContext(DestinationContext)
    
    const [checked, setChecked] = useState(content.isActivate)

    const handleChecked = (checked: boolean) => {
        setChecked(checked)
        const listCopy = [...listDestinations]
        let editDesti: Destination = listDestinations.find((destination: Destination) => destination.name === content.name)! //The best will be to have a real id
        const indexOfDesti = listDestinations.indexOf(editDesti)
        editDesti = {...editDesti, isActivate: checked}
        listCopy[indexOfDesti] = editDesti;
        updateList(listCopy);
    }

    return (
        <article className='destination'>
            <div className='destination__img'>
                <img src={content.imgLink} alt={content.name}/>
            </div>
            <div className='destination__description'>
                <div className='destination__nameContainer'>
                    <h2 className='destination__name'>{content.name}</h2>
                    <h3 className='destination__adress'>{content.adress}</h3>
                </div>
                <Switch checked={checked} onSelect={(checked: boolean)=> handleChecked(checked)}/> 
            </div>
            <div className='destination__infos'>
                <div className='info'>
                    <h4 className='info__value'>{content.nbResident} M</h4>
                    <p className='info__subtitle'>Habitants</p>
                </div>
                <div className='info'>
                    <h4 className='info__value'>{content.nbHotel}</h4>
                    <p className='info__subtitle'>Hôtels</p>
                </div>
                <div className='info'>
                    <h4 className='info__value'>{content.avgIncome} €</h4>
                    <p className='info__subtitle'>Revenu Moy</p>
                </div>
                <div className='info'>
                    <h4 className='info__value'>{content.area}</h4>
                    <p className='info__subtitle'>km²</p>
                </div>
            </div>
        </article>
    );
}
  
export default DestinationItem;
  