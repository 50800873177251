import './Switch.scss';
import {useState} from 'react';

function Switch(props: {onSelect: Function, checked: Boolean}) {

    const {
        onSelect,
        checked
    } = props

    const handleClick = () => {
        onSelect(!checked);
    }

    return (
        <>
            <div data-testid="switch" onClick={() => handleClick()} className={`switch ${checked ? 'switch--isChecked' : ''}`}></div>
        </>
    );
}

export default Switch;
  