import './DestinationForm.scss';
import Switch from '../Switch/Switch';
import {useContext, useEffect, useState,} from 'react';
import Destination from '../../lib/interfaces';
import DestinationContext from '../../lib/DestinationContext';

function DestinationForm(props: {isShow: boolean, clickIsOutside: Function}) {

    const { listDestinations, updateList } = useContext(DestinationContext);

    const {
        isShow,
        clickIsOutside,
    } = props

    const [checked, setChecked] = useState(false)
    const [name, setName] = useState('')
    const [adress, setAdress] = useState('')
    const [nbResident, setNbResident] = useState(0)
    const [imgLink, setImgLink] = useState('')
    const [nbHotel, setNbHotel] = useState(0)
    const [avgIncome, setAvgIncome] = useState(0)
    const [area, setArea] = useState(0)

    const checkClick = (e: HTMLElement) => {
        const clickOutside: boolean = document.getElementsByClassName('form__modal')[0].contains(e)
        if (!clickOutside) {
            clickIsOutside(clickOutside)
        }
    }

    useEffect(() => {
        const detectEchap = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && isShow === true) {
                clickIsOutside(true)
            }
        }

        document.getElementById('focus')?.focus();
        document.addEventListener('keydown', (event) => detectEchap(event));
        return () => {
            document.removeEventListener('keydown', (event) => detectEchap(event));
        };
    }, [clickIsOutside, isShow]);

    const addDestination = () => {
        const newDestination: Destination = {
            name,
            adress,
            imgLink,
            nbResident,
            nbHotel,
            avgIncome, 
            area,
            isActivate: checked
        }
        const destinationList = [...listDestinations]
        destinationList.push(newDestination)
        updateList(destinationList)
        setChecked(false)
        setName('')
        setAdress('')
        setNbResident(0)
        setImgLink('')
        setNbHotel(0)
        setAvgIncome(0)
        setArea(0)
        clickIsOutside(true)
    }

    return (
        <div className={`form ${isShow ? 'form--isShow' : ''}`}  onClick={(e) => checkClick(e.target as HTMLElement)}>
            <div className='form__modal'>
                <form>
                    <h2 className='form__title'>Ajouter une nouvelle destination</h2>
                    <input id="focus" type='text' placeholder='Nom de la destination' value={name} onChange={(e) => setName(e.target.value)}/>
                    <input type='text' placeholder='Adresse' value={adress} onChange={(e) => setAdress(e.target.value)}/>
                    <input type='text' placeholder="Lien de l'image" value={imgLink} onChange={(e) => setImgLink(e.target.value)}/>
                    <div className='form__inputsContainer'>
                        <input type='number' placeholder='Nb. Habitants' value={nbResident} onChange={(e) => setNbResident(parseInt(e.target.value))}/>
                        <input type='number' placeholder='Nb. Hôtels' value={nbHotel} onChange={(e) => setNbHotel(parseInt(e.target.value))}/>
                        <input type='number' placeholder='Revenu moy' value={avgIncome} onChange={(e) => setAvgIncome(parseInt(e.target.value))}/>
                        <input type='number' placeholder='Superficie' value={area} onChange={(e) => setArea(parseInt(e.target.value))}/>
                    </div>
                    <div className='form__switchContainer'>
                        <Switch checked={checked}  onSelect={(checked: boolean)=> setChecked(checked)}/> 
                        <p>Activer</p>
                    </div>
                    <div className='form__actionsContainer'>
                        <button onClick={()=> clickIsOutside(false)} className='form__action form__action--cancel'>Cancel</button>
                        <button onClick={(e)=> {
                            e.preventDefault()
                            addDestination()
                        }} className='form__action form__action--confirm'>Confirm</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DestinationForm;
  